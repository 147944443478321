.root-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.typography {
  text-align: left;
}
.outlined-Input {
  height: 2rem;
  padding: 0; 
  border-radius: 0;
  color:black;
}
.merchandise-container {
  margin-bottom: 1rem;
}
.merchandise-bottom {
  margin-bottom: 4rem;
}
.merchandise-addButton {
  display: inline-block !important;
  border-radius: 0px !important;
  width: 50vw;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.merchandise-cancelButton {
  display: inline-block !important;
  border-radius: 0 !important;
  width: 50vw !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  text-align: center;
}
.button-container {
  position: fixed;
  left: 0;
  bottom: 0;
}
