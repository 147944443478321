.overlay__content, .overlay__content video {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.drawingBuffer {
  position: absolute;
  z-index: 500;
}
