.dialogTitle {
  color: #000 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.formControl {
  padding: 0px !important;
}

.doneBtn {
  margin: 10px !important;
}
